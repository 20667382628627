<template>
  <v-dialog v-model="dialog" eager :width="actualFormWidth" persistent>
    <BaseEditCardEntity
      :entity="currentEntity"
      :tab-items="editCardItems"
      entity-type="metaholding"
      :is-edit="isEditForm"
      v-on:close="closeNewMetaHolding"
      :save-item="saveMetaHolding"
      :key="currentEntity.id"
      :is-loading="loadingMetaHolding"
      :active-tab.sync="activeTab"
      ref="meta-holding-card-entity"
      v-on:update:activeTab="changeFormWidth($event)"
    >
      <template v-slot:CRM="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
          statusNeededText="Admin & Finance"
        />
        <v-row wrap>
          <v-col cols="12">
            <v-text-field
              label="Name"
              v-model.trim="entity.name"
              required
              :error-messages="errorMessageName(entity.name)"
              :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="ID"
              :value="entity.id"
              disabled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:HOLDINGS="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
          statusNeededText="Admin & Finance"
        />
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text @click="createNewHolding" :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)">
              <v-icon>
                add
              </v-icon>
              Add new holding
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-data-table
          eager
          :items="holdingsOfMetaHolding"
          :headers="headerHolding"
          :loading="loadingHoldings"
          class="children-table"
          :footer-props="{
            itemsPerPageOptions: [20,50,100, {text: 'All', value: -1}]
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                {{ props.item.name }}
              </td>
              <td>
                <v-btn icon @click="editHolding(props.item)" :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <template v-slot:BILLING="{ entity }">
        <BillingForm
          :entity="entity"
          :entityType="'metaholding'"
          ref="billingForm"
          :new-form="defaultEntity ? true : false"
        >
        </BillingForm>
      </template>
      <template v-slot:HISTORY="{ entity }">
        <HistoryLogsComponent
          :entityId="currentEntity.id"
          entityType="meta_holding"
      ></HistoryLogsComponent>
      </template>
    </BaseEditCardEntity>
  </v-dialog>
</template>

<script>
import BaseEditCardEntity from '@/components/KeystoneV2/BaseEditCardEntity'
import { rulesMixin } from '@/mixins/rulesMixin'
import { entityFormMixin } from '@/mixins/entityFormMixin'
import MetaHoldingModel from '@/models/Keystone_v2/MetaHoldingModel'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import BillingAccountModel from '@/models/Keystone_v2/BillingAccountModel'
import BillingForm from '@/components/TableComponents/Form/BillingForm'
import { callEditFormMixin } from '@/mixins/callEditFormMixin'
import store from '../../../../store'
import { common } from '../../../../store/modules/common'
import { snakeCaseConverterMixin } from '@/mixins/snakeCaseConverterMixin'
import { UserStatus } from '../../../../types/user_types'
import DisabledFieldsAlertMessage from '@/components/KeystoneV2/tools/DisabledFieldsAlertMessage.vue'
import HistoryLogsComponent from '../../TableComponents/Form/FormFragments/HistoryLogs.vue'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'MetaHoldingForm',
  components: { BaseEditCardEntity, BillingForm, DisabledFieldsAlertMessage, HistoryLogsComponent },
  mixins: [rulesMixin, entityFormMixin, callEditFormMixin, snakeCaseConverterMixin],
  props: {},
  data: function () {
    return {
      formsWidth: {
        'default': '600px',
        'billing': '1200px',
        'history': '1000px'
      },
      actualFormWidth: '600px',
      editCardItems: [
        { title: 'CRM' },
        { title: 'HOLDINGS', disabled: () => { return !this.isEditForm } },
        { title: 'BILLING' },
        { title: 'HISTORY', disabled: () => { return !this.isEditForm } }
      ],
      loadingMetaHolding: false,
      loadingHoldings: false,
      holdingsOfMetaHolding: [],
      headerHolding: [
        { text: 'name', value: 'name', width: '80%' },
        { text: 'edit', value: '', sortable: false, width: '20%' }
      ],
      entityType: 'metaholding',
      activeTab: 0,
      UserStatus: UserStatus
    }
  },
  mounted () {
    if (this.$store.getters.getEditBilling) {
      this.activeTab = 2
      this.changeFormWidth(2)
    }
  },
  methods: {
    errorMessageIntegrity (name) {
      if (!this?.$store?.getters?.getMetaHoldings) {
        return []
      }
      let metaholdingsName = this.getEntitiesNameToTest(this.$store.getters.getMetaHoldings)
      return this.tempErrorMessageIntegrity(name, metaholdingsName)
    },
    errorMessageName (name) {
      return [...this.errorMessageIntegrity(name), ...this.errorMessageValueCantBeEmpty(name, 'name')]
    },
    defineMsaTypeItemsForNewForm () {
      this.$refs.billingForm.defineMsaTypeItems('metaholding')
    },
    closeNewMetaHolding () {
      this.dialog = false
      this.holdingsOfMetaHolding = []
      this.activeTab = 0
      this.actualFormWidth = this.formsWidth.default
      this.callEditFormFunc(
        'openNewFormHolding',
        'openEditFormHolding'
      )
    },
    /**
     * @param metaHolding {MetaHoldingModel}
     * @return {Promise<void>}
     */
    async saveMetaHolding (metaHolding) {
      this.processingMetaHolding = true
      let options = {
        name: metaHolding.name,
        has_fit_score: Boolean(metaHolding.hasFitScore),
        has_insight: Boolean(metaHolding.hasInsight)
      }

      options.billing_account = this.snakeCaseConverter(this.$refs.billingForm.fetchData())

      let result = null
      let success = false

      if (this.isEditForm) {
        options.id = metaHolding.id
        result = await this.$store.dispatch('updateMetaHolding', options)
      } else {
        result = await this.$store.dispatch('createMetaHolding', options)
      }

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when process')
        this.$store.commit('setErrorMessageWithResponse', result)
        const barConfig = {
          type: 'error',
          message: 'Error on update or create metaholding'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      } else {
        console.warn('Process success')
        success = true
        const barConfig = {
          type: 'success',
          message: 'Success !'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      }

      this.processingMetaHolding = false
      this.$emit('refresh')

      if (!this.isEditForm && success) {
        this.currentEntity = new MetaHoldingModel(result.data)
        this.$emit('update:isEditForm', true)
        this.activeTab = 2
      } else {
        this.closeNewMetaHolding()
      }
    },
    async getCurrentEntity (metaHoldingId) {
      this.loadingMetaHolding = true
      this.currentEntity = new MetaHoldingModel()

      const result = await this.$apiCaller.getMetaHolding(metaHoldingId)
      if (this.$apiCaller.isResponseError(result)) {
        this.$store.commit('setErrorMessageWithResponse', result)
        this.closeNewMetaHolding()
      } else {
        this.currentEntity = new MetaHoldingModel(result.data)
      }

      this.loadingMetaHolding = false
    },
    async createNewHolding () {
      this.loadingMetaHolding = true
      let cloned = this.$commonUtils.copyInstance(this.currentEntity)
      let result = await this.$apiCaller.getBillingAccountFromEntity('metaholding', this.currentEntity.id)
      if (this.$apiCaller.isResponseError(result)) {
        this.$store.commit('setErrorMessageWithResponse', result)
        this.loadingMetaHolding = false
        this.closeNewMetaHolding()
      } else {
        cloned.billingAccount = new BillingAccountModel(result.data)
        this.activeCallNewForm(cloned)
        this.loadingMetaHolding = false
        this.$refs['meta-holding-card-entity'].__closeDialogProcess()
      }
    },
    /**
     * @param holding {HoldingModel}
     */
    editHolding (holding) {
      this.activeCallEditForm(holding)
      this.$refs['meta-holding-card-entity'].__closeDialogProcess()
    },
    async getChildOfCurrentEntity () {
      this.loadingHoldings = true
      this.holdingsOfMetaHolding = []
      const data = {
        parent_id: this.currentEntity.id
      }
      const result = await this.$apiCaller.getHoldings(data)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling holdings of metaholding')
        this.$store.commit('setErrorMessageWithResponse', result)
      } else {
        for (let i in result.data) {
          if (result.data.hasOwnProperty(i)) {
            this.holdingsOfMetaHolding.push(new HoldingModel(result.data[i]))
          }
        }
      }

      this.loadingHoldings = false
    },
    changeFormWidth (value) {
      if (value === 2) {
        this.actualFormWidth = this.formsWidth.billing
      } else if (value === 3) {
        this.actualFormWidth = this.formsWidth.history
      } else {
        this.actualFormWidth = this.formsWidth.default
      }
    },
    disabledIfNoRights (roles = null) {
      return !this.$commonUtils.hasUserCorrectStatus(this.$store.getters.getUserStatus, roles)
    }
  },
  computed: {
    isInsightDisabled () {
      return !this.$store.getters.isUserSettupper
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.redirect-slack {
  text-decoration: none;
}
.redirect-slack:hover {
  text-decoration: underline;
}
</style>
